html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Questrial', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  /* letter-spacing: 0.04rem; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  /* letter-spacing: 0.04rem; */
}

input {
  font-family: -apple-system, BlinkMacSystemFont, 'Questrial', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  /* letter-spacing: 0.04rem; */
}

select {
  font-family: -apple-system, BlinkMacSystemFont, 'Questrial', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  /* letter-spacing: 0.04rem; */
}

:root {
  /* Colors */
  --primary-color: #f05a5a;
  --primary-color-medium: #d56565;
  --primary-color-light: #de7f7f;
  --primary-color-dark: #cf2e2e;
  --secondary-color: #3f51b5;
  --secondary-color-light: #7986cb;
  --secondary-color-dark: #303f9f;
  --font-color: rgb(60, 60, 60);
  --font-color-light: rgb(140, 140, 140);
  --font-color-invert: #fff;
  --navigation-font-color: #fff;
  --label-color: rgba(0, 0, 0, 0.6);
  --background-color: #fff;
  --background-color-values: 255, 255, 255;
  --box-shadow-color: rgba(0, 0, 0, 0.2);
  --border-color-light: rgba(0, 0, 0, 0.08);
  --table-background-color: #fff;
  --table-background-color-values: 255, 255, 255;
  --table-header-background-color: #fff;
  --table-header-background-color-values: 255, 255, 255;
  --table-header-week-even-background-color: rgba(245, 245, 245, 0.7);
  --table-header-week-odd-background-color: rgba(245, 245, 245, 0.3);
  --table-font-color: rgb(60, 60, 60);
  --table-font-color-alternate: #fff;
  --table-font-color-active: #fff;
  --table-input-border-color: rgba(0, 0, 0, 0.08);
  --table-input-border-color-active: rgba(255, 255, 255, 0.12);
  --table-input-border-color-active-focus: #fff;
  --table-input-border-color-focus: var(--primary-color);
  --extra-booking-color: rgba(255, 138, 101, 0.2);
  --extra-booking-color-font: #5e77f3;
  --overlimit-background-color: rgb(236, 41, 41);
  --modal-background-color: #fff;
  --modal-header-font-color: #fff;
  --export-card-color: #fff;
  --loading-spinner-color: #fff;
  --field-underline-color: rgba(0, 0, 0, 0.42);
  --success-color: rgb(23, 92, 44);
  --error-color: #b00020;
  --warning-color: rgb(225, 182, 0);
  --icon-success-color: var(--success-color);
  --icon-error-color: var(--error-color);
  --weekend-color: rgb(248, 230, 149);
  --holiday-color: rgb(179, 240, 179);
  --backdrop-color: rgba(0, 0, 0, 0.1);
  --backdrop-background-color: rgba(255, 255, 255, 1);
  --editable-field-color: #fff2cc;
  --disabled-color: rgba(0, 0, 0, 0.26);
  color: var(--font-color);

  /* Fonts */
  --basic-font-size: 13px;
  --table-font-size: 0.8rem;
  --table-font-weight: 400;
  font-size: var(--basic-font-size);

  /* Navigation */
  --navbar-height: 3.5rem;
  --navbar-animation-speed: 500ms;

  /* Boxes */
  --small-box-shadow: 0 0.25rem 0.5rem -0.2rem var(--box-shadow-color), 0 0 0.5rem -0.2rem var(--box-shadow-color);
  --small-box-shadow-expanded: 0 0.25rem 0.5rem -0.2rem var(--box-shadow-color), 0 0 0.5rem -0.5rem var(--box-shadow-color);
  --medium-box-shadow: 0 0.5rem 1rem -0.5rem var(--box-shadow-color), 0 0 1rem -0.5rem var(--box-shadow-color);
  --big-box-shadow: 0 1rem 2rem -1rem var(--box-shadow-color), 0 0 2rem -1rem var(--box-shadow-color);
  --transition-time: 0.2s;
  --theme-transition: background-color var(--transition-time) easy-in-out, color var(--transition-time) easy-in-out;

  /* Bookings */
  --total-field-width: 4.5rem;
  --booking-field-width: 7.5rem;
  --booking-numeric-input-width: 2rem;
  --booking-rate-input-width: 2.2rem;
  --booking-select-width: 2.1rem;
  --timepicker-width: 2.3rem;
  --measure-field-width: 4.5rem;
  --measure-numeric-input-width: 3.3rem;
}

[data-theme='dark'] {
  --primary-color: #f05a5a;
  --primary-color-medium: #d56565;
  --primary-color-light: #de7f7f;
  --primary-color-dark: #cf2e2e;
  --secondary-color: #3f51b5;
  --secondary-color-light: #7986cb;
  --secondary-color-dark: #303f9f;
  --font-color: rgba(255, 255, 255, 0.8);
  --font-color-light: #fff;
  --font-color-invert: rgb(3, 3, 3);
  --navigation-font-color: #fff;
  --label-color: rgba(255, 255, 255, 0.8);
  --background-color: rgb(24, 24, 24);
  --background-color-values: 24, 24, 24;
  --box-shadow-color: rgb(61, 60, 60);
  --border-color-light: rgba(81, 81, 81, 1);
  --table-background-color: #1e1e1e;
  --table-background-color-values: 30, 30, 30;
  --table-header-background-color: #1e1e1e;
  --table-header-background-color-values: 18, 18, 18;
  --table-header-week-even-background-color: rgba(25, 25, 25, 1);
  --table-header-week-odd-background-color: rgba(30, 30, 30, 1);
  --table-font-color: rgba(255, 255, 255, 0.8);
  --table-font-color-alternate: rgba(255, 255, 255, 0.8);
  --table-font-color-active: rgba(255, 255, 255, 0.8);
  --table-input-border-color: rgba(255, 255, 255, 0.12);
  --table-input-border-color-active: rgba(255, 255, 255, 0.12);
  --table-input-border-color-active-focus: #fff;
  --table-input-border-color-focus: #fff;
  --extra-booking-color: rgb(155, 151, 151);
  --extra-booking-color-font: #2346f3;
  --overlimit-background-color: #b00020;
  --modal-background-color: rgba(0, 0, 0, 0.9);
  --modal-header-font-color: #fff;
  --export-card-color: rgba(81, 81, 81, 0.5);
  --loading-spinner-color: rgba(81, 81, 81, 1);
  --field-underline-color: rgba(81, 81, 81, 1);
  --success-color: rgb(23, 92, 44);
  --error-color: #b00020;
  --warning-color: rgb(225, 182, 0);
  --icon-success-color: var(--success-color);
  --icon-error-color: var(--error-color);
  --weekend-color: rgb(153, 126, 7);
  --holiday-color: rgb(23, 92, 44);
  --backdrop-color: rgba(0, 0, 0, 0.1);
  --backdrop-background-color: rgba(255, 255, 255, 1);
  --editable-field-color: rgba(81, 81, 81, 1);
  --disabled-color: rgba(255, 255, 255, 0.3);
  color: var(--font-color);

  /* Boxes */
  --small-box-shadow: 0 0.25rem 0.5rem -0.2rem var(--box-shadow-color), 0 0 0.5rem -0.2rem var(--box-shadow-color);
  --small-box-shadow-expanded: 0 0.25rem 0.5rem -0.2rem var(--box-shadow-color), 0 0 0.5rem -0.5rem var(--box-shadow-color);
  --medium-box-shadow: 0 0.5rem 1rem -0.5rem var(--box-shadow-color), 0 0 1rem -0.5rem var(--box-shadow-color);
  --big-box-shadow: 0 1rem 2rem -1rem var(--box-shadow-color), 0 0 2rem -1rem var(--box-shadow-color);
  --transition-time: 0.2s;
  --theme-transition: background-color var(--transition-time) easy-in-out, color var(--transition-time) easy-in-out;
}

/* Hide numeric spinner */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide numeric spinner (Firefox) */
input[type=number] {
  -moz-appearance: textfield;
}